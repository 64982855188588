<template>
  <div class="banner-cards" :class="type ? type : ''">
    <router-link
      :to="getHref(banners[type == 'mini' ? 4 : 0], defaultBanners[type == 'mini' ? 3 : 0]?.url)"
      class="banner-card "
      @click="bannerClick(banners[type == 'mini' ? 4 : 0])"
      :style="getBannerImage(banners[type == 'mini' ? 4 : 0], defaultBanners[type == 'mini' ? 3 : 0]?.image_url)"
    >
      <!-- <div
        class="banner-card-inner"
        v-if="banners[type == 'mini' ? 3 : 0]?.video_url == null"
      ></div>

      <div v-else>
        <button class="play-icon" @click.prevent="showVideo = true">
          <svg
            class="-mr-0.5 w-5 h-5 flex-shrink-0"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="currentColor"
              d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
            />
          </svg>
        </button>
        <VimeoVideo
          @close="showVideo = false"
          :isVisible="showVideo"
          :videoId="getVideoUrl(banners[type == 'mini' ? 3 : 0])"
        />
      </div> -->
    </router-link>
    <router-link
      :to="getHref(banners[type == 'mini' ? 5 : 1], defaultBanners[type == 'mini' ? 4 : 1]?.url)"
      class="banner-card "
      @click="bannerClick(banners[type == 'mini' ? 5 : 1])"
      :style="getBannerImage(banners[type == 'mini' ? 5 : 1], defaultBanners[type == 'mini' ? 4 : 1]?.image_url)"
    >
      <!-- <div
        class="banner-card-inner"
        v-if="banners[type == 'mini' ? 4 : 1]?.video_url == null"
      ></div>
      <div v-else>
        <button class="play-icon" @click.prevent="showVideo1 = true">
          <svg
            class="-mr-0.5 w-5 h-5 flex-shrink-0"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="currentColor"
              d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
            />
          </svg>
        </button>
        <VimeoVideo
          @close="showVideo1 = false"
          :isVisible="showVideo1"
          :videoId="getVideoUrl(banners[type == 'mini' ? 4 : 1])"
        />
      </div> -->
    </router-link>
  </div>
</template>

<script>
// import VimeoVideo from "@/components/VimeoVideo.vue";
import Segments from "@/modules/Segments";

const { BannerVisit } = Segments();
export default {
  name: "BannerCards",
  // components: { VimeoVideo },
  props: {
    banners: {
      type: Object,
      default: () => {}
    },
    defaultBanners: Array,
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showVideo: false,
      showVideo1: false,
    };
  },
  methods: {
    getUrl(url) {
      return url.replace("https://medusadistribution.com/", "/");
    },
    getVideoUrl(banner) {
      return banner?.video_url != null ? banner.video_url : "";
    },
    bannerClick(banner){
      BannerVisit(banner);
    },
    getHref(banner, temp) {
      // Ensure winners is an array
      const winners = Array.isArray(banner?.winners) ? banner.winners : [];
      if (winners.length === 0) {
        return (temp ? temp : '/');
      }

      // Get the first winner
      const winner = winners[0];
      const href = winner?.type === 'product'
        ? `/products/${winner?.id}`
        : typeof winner?.id === 'string'
          ? winner?.id?.replace("https://medusadistribution.com/", "/")
          : (temp ? temp : '/');

      return href;
    },
    getBannerImage(banner, temp) {
        return banner?.winners?.length
          ? `background-image: url('${
            banner?.winners[0]?.asset[0]?.url
            }')`
          : `background-image: url('${temp}')`;
      }
    }
};
</script>

<style lang="scss" scoped>
.banner-cards {
  @apply grid grid-cols-1 lg:grid-cols-2 gap-5;

  .banner-card {
    @apply flex flex-col items-start justify-end p-5 md:p-10 relative rounded-lg text-white bg-no-repeat  bg-white bg-cover bg-center overflow-hidden max-w-full;

    aspect-ratio: 556/285;

    .banner-card-inner {
      @apply relative z-10 space-y-8;
    }
  }

  &.mini {
    .banner-card {
      aspect-ratio: 556/105;
    }
  }

  h3 {
    @apply text-lg md:text-2xl font-semibold mb-3;
  }

  p {
    @apply text-base md:text-xl font-medium text-opacity-60 text-white max-w-xs;
  }

  button {
    @apply inline-block text-sm font-medium bg-white text-black px-6 py-1.5 rounded shadow;
  }

  .play-icon {
    @apply inline-flex justify-center items-center bg-white w-12 h-12 rounded-full text-black focus:outline-none absolute right-8 bottom-8;

    svg {
      @apply w-6 h-6;
    }
  }
}
</style>
