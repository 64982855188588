<template>
  <div class="home-page max-container">
    <div class="home-page-inner">
      <div class="space-y-6" v-if="!isLoggedIn">
        <GuestBanner
          class="dark"
          title="Retailers trust Medusa to help them grow."
          description="Medusa provides smart analytics so you can source the top products and brands for your store's success."
          button="Join Now"
          background="/images/home-banner.png"
        />
        <ExploreCards />
      </div>
      <BannerCards v-else :banners="auctions" :defaultBanners="Banners.data" />

      <div class="new-arrivals-row">
        <Card class="h-full">
          <div class="card-color-header">
            <h3>New Arrivals</h3>
            <router-link
              :to="'/collection/new-arrivals'"
              class="flex items-center uppercase text-sm"
            >
              View All
              <Icon class="w-5 h-5 -my-2 ml-1" name="right-arrow" />
            </router-link>
          </div>

          <NewArrivals />
        </Card>
      </div>

      <!-- Testimonial Slider -->
      <TestimonialSlider v-if="!isLoggedIn" />

      <!-- Get Started and Lightning Info Row -->
      <RowGridWrapper :inverse="true" v-if="!isLoggedIn" :disable-ratio="true">
        <LightningOrderInfo class="h-full" />
        <template #aside>
          <Card class="flex h-full" title="Get Started">
            <template #action>
              <router-link
                :to="'/join-us'"
                class="flex items-center uppercase text-sm"
              >
                APPLY NOW
                <Icon class="w-5 h-5 -my-2 ml-1" name="right-arrow" />
              </router-link>
            </template>
            <GetStarted />
          </Card>
        </template>
      </RowGridWrapper>

      <div class="brand-rows" v-if="auctions.length">
        <TrendingCard
          :place="
            getSelectedAddress
              ? getSelectedAddress.state_data?.state_name || ''
              : ''
          "
          :title="
            getSelectedAddress
              ? getSelectedAddress.state_data?.state_name || ''
              : ''
          "
          :isLoggedIn="isLoggedIn"
          class="fixed-aspect-ratio"
        />
        <ImageCard
          :url="getHref(auctions[2], Banners.data[2]?.url)"
          :bg-image="getBannerImage(auctions[2], Banners.data[2]?.image_url)"
          cta=""
          class="fixed-aspect-ratio"
          @click="bannerClick(auctions[2])"
        />
        <ImageCard
          :url="getHref(auctions[3], Banners.data[5]?.url)"
          :bg-image="getBannerImage(auctions[3], Banners.data[5]?.image_url)"
          class="align-top fixed-aspect-ratio"
          @click="bannerClick(auctions[3])"
        />
        <!-- <ImageCard
          :url="
            Banners.data[2] ? getUrl(Banners.data[2]?.url) : '/products/micro'
          "
          :text="Banners.data[2] ? Banners.data[2]?.sub_title : 'Micro'"
          :logo="
            Banners.data[2]?.title == 'Cartisan'
              ? '/images/cartisan-logo.svg'
              : ''
          "
          :title="
            Banners.data[2]?.title != 'Cartisan' ? Banners.data[3]?.title : ''
          "
          :bg-image="
            Banners.data[2]
              ? Banners.data[2]?.image_url
              : '/images/banner/micro.png'
          "
          cta=""
          class="fixed-aspect-ratio"
          @click="bannerClick(Banners.data[2])"
        />
        <ImageCard
          :url="
            Banners.data[5]
              ? getUrl(Banners.data[5]?.url)
              : '/products/medusa-replacement-glass'
          "
          :bg-image="
            Banners.data[5]
              ? Banners.data[5]?.image_url
              : '/images/banner/B4.png'
          "
          :text="
            Banners.data[5] ? Banners.data[5]?.sub_title : 'Replacement Glass'
          "
          :title="Banners.data[5] ? Banners.data[5]?.title : 'MEDUSA'"
          class="align-top fixed-aspect-ratio"
          @click="bannerClick(Banners.data[5])"
        /> -->
      </div>

      <BannerCards type="mini" :banners="auctions" :defaultBanners="Banners.data" />

      <div v-if="!isLoggedIn"><SupportCard /></div>

      <Card class="flex" title="Explore Categories">
        <template #action>
          <router-link
            to="/categories"
            class="flex items-center uppercase text-sm"
          >
            View All
            <Icon class="w-5 h-5 -my-2 ml-1" name="right-arrow" />
          </router-link>
        </template>
        <div
          v-if="categories.loading"
          class="grid w-full grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-x-5 gap-y-8"
        >
          <VerticalProductLoader v-for="x in 6" :key="x" type="category" />
        </div>
        <div
          v-else
          class="grid w-full grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-x-5 gap-y-8"
        >
          <router-link
            v-for="(category, ind) in shuffled(categories.data)"
            :key="`${category.name}-${ind}`"
            class="prod-item flex flex-col items-center space-y-1.5 leading-none text-center"
            :to="slugUrl('category/' + category.slug)"
          >
            <div
              class="prod-image custom w-28 h-28 rounded-full"
              style="background-color: #EEE5DB"
            >
              <img
                class="custom block w-20 h-20 object-contain"
                :alt="category.name"
                :src="
                  category.image
                    ? imageBaseUrl(category.image)
                    : '/images/no-image.png'
                "
              />
            </div>
            <p>{{ category.name }}</p>
            <span>{{ category.brand }}</span>
          </router-link>
        </div>
      </Card>

      <div class="grid grid-cols-1 xl:grid-cols-2 gap-4 md:gap-8">
        <Card class="flex" title="Staff Picks">
          <template #action>
            <router-link
              :to="'/collection/staff-picks'"
              class="flex items-center uppercase text-sm"
            >
              View All
              <Icon class="w-5 h-5 -my-2 ml-1" name="right-arrow" />
            </router-link>
          </template>
          <div
            v-if="collectionstaff.loading"
            class="grid w-full grid-cols-2 md:grid-cols-3 gap-5"
          >
            <VerticalProductLoader v-for="x in 3" :key="x" />
          </div>
          <div v-else class="grid w-full grid-cols-2 md:grid-cols-3 gap-5">
            <a
              v-for="(item, i) in collectionstaff.data
                ? collectionstaff.data.slice(0, 3)
                : []"
              :key="item.id"
              class="prod-item cursor-pointer"
              @click="gotToPage(item, i, 'staff-picks')"
            >
              <div class="prod-image">
                <img :alt="item.name" :src="getImage(item)" />
              </div>
              <p>{{ item.name }}</p>
              <span>{{ item.brand }}</span>
            </a>
          </div>
        </Card>
        <Card class="flex" title="Clearance">
          <template #action>
            <router-link
              :to="'/clearance'"
              class="flex items-center uppercase text-sm"
            >
              View All
              <Icon class="w-5 h-5 -my-2 ml-1" name="right-arrow" />
            </router-link>
          </template>
          <div
            v-if="collectionclearance.loading"
            class="grid w-full grid-cols-2 md:grid-cols-3 gap-5"
          >
            <VerticalProductLoader v-for="x in 3" :key="x" />
          </div>
          <div v-else class="grid w-full grid-cols-2 md:grid-cols-3 gap-5">
            <a
              v-for="(clearance, i) in collectionclearance.data
                ? collectionclearance.data.slice(0, 3)
                : null"
              :key="clearance.id"
              class="prod-item cursor-pointer"
              @click="gotToPage(clearance, i, 'clearance')"
            >
              <div class="prod-image">
                <img :alt="clearance.name" :src="getImage(clearance)" />
              </div>
              <p>{{ clearance.name }}</p>
              <span>{{ clearance.brand }}</span>
            </a>
          </div>
        </Card>
      </div>

      <Card class="flex" title="New Brands">
        <template #action>
          <router-link to="/brands" class="flex items-center uppercase text-sm">
            View All
            <Icon class="w-5 h-5 -my-2 ml-1" name="right-arrow" />
          </router-link>
        </template>
        <div
          v-if="newBrands.loading"
          class="grid w-full grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-8"
        >
          <VerticalProductLoader v-for="x in 6" :key="x" type="brand" />
        </div>
        <div
          v-if="!newBrands.loading"
          class="grid w-full grid-cols-2  md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-8"
        >
          <router-link
            v-for="({ image, name, slug }, ind) in newBrands.data.filter(b => isLoggedIn || !b.login_required == '0').slice(0, 6)"
            :key="`${name}-${ind}`"
            class="prod-item"
            :to="slugUrl('brand/' + slug)"
          >
            <div class="prod-image custom h-full">
              <img
                :alt="name"
                :src="image ? imageBaseUrl(image) : '/images/no-image.png'"
              />
            </div>
            <p>{{ name }}</p>
            <!--<span>{{ brand }}</span>-->
          </router-link>
        </div>
      </Card>

      <Card class="flex" title="Best Selling" v-if="isLoggedIn">
        <template #action>
          <router-link
            to="/trending"
            class="flex items-center uppercase text-sm"
          >
            View All
            <Icon class="w-5 h-5 -my-2 ml-1" name="right-arrow" />
          </router-link>
        </template>
        <!-- v-for="(imageUrl ,brand, name) in topSellProducts.data.slice(0, 6)" :key="`${name}-${ind}`" -->
        <div
          v-if="topSellProducts.loading"
          class="grid w-full grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-5"
        >
          <VerticalProductLoader v-for="x in 6" :key="x" />
        </div>
        <div
          v-else
          class="grid w-full grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-5"
        >
          <a
            v-for="(topsellproduct, i) in topSellProducts.data"
            :key="topsellproduct.id"
            class="prod-item"
            @click="gotToPage(topsellproduct, i, 'trending')"
          >
            <div class="prod-image cursor-pointer">
              <img
                :alt="topsellproduct.name"
                :src="getImage(topsellproduct)"
              />
            </div>
            <p>{{ topsellproduct ? topsellproduct.name : null }}</p>
            <span>{{ topsellproduct.brand.name }}</span>
          </a>
        </div>
        <div v-if="!isLoggedIn" class="grid w-full grid-cols-15 gap-5">
          <router-link class="prod-item" to="/login">
            <p>Require User Login</p>
          </router-link>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import TrendingCard from "./modules/TrendingCard.vue";
import ImageCard from "./modules/ImageCard.vue";
import Collections from "@/modules/Collections";
import Brands from "@/modules/Brands";
import Categories from "@/modules/Categories";
import BannerCards from "./modules/BannerCards.vue";
import ExploreCards from "./modules/ExploreCards.vue";
import Segments from "@/modules/Segments";
import NewArrivals from "./modules/NewArrivals.vue";
import VerticalProductLoader from "@/components/loaders/VerticalProduct.vue";
import TestimonialSlider from "@/components/TestimonialSlider";
import GuestBanner from "@/components/GuestBanner";

import { mapGetters, useStore } from "vuex";
import GetStarted from "./modules/GetStarted.vue";
import LightningOrderInfo from "./modules/LightningOrderInfo.vue";
import SupportCard from "./modules/SupportCard.vue";
import { inject, reactive, ref } from "vue";
import RowGridWrapper from "./modules/RowGridWrapper.vue";
import { useRouter } from 'vue-router';
import axios1 from 'axios';

export default {
  name: "Home",
  components: {
    TrendingCard,
    ImageCard,
    BannerCards,
    NewArrivals,
    VerticalProductLoader,
    TestimonialSlider,
    GuestBanner,
    ExploreCards,
    GetStarted,
    LightningOrderInfo,
    SupportCard,
    RowGridWrapper,
  },
  computed: {
    ...mapGetters(["getSelectedAddress"]),

  },
  mounted() {
    const isLog = localStorage.getItem("IsloggIn");
    if (isLog) {
      // Get Route
      const route = this.$route;
      const hash = localStorage.getItem("user_hash");
      const email = localStorage.getItem("email");
      this.TrackUserLogin(hash, email, route);
      localStorage.removeItem("IsloggIn");
    }
  },
  setup() {
    const axios = inject("axios");
    const showVideo = ref(false);
    const router = useRouter();
    const store = useStore();
    
    const collectionstaff = reactive({
      loading: true,
      data: []
    });

    const collectionclearance = reactive({
      loading: true,
      data: []
    });

    const Banners = reactive({
      loading: false,
      data: [],
      error: "",
    });

    const auctions = ref({});

    const slugUrl = (slug) => {
      const base_url = process.env.BASE_URL || "/";
      return base_url + slug;
    };

    const { getCollection } = Collections();
    const { newBrands, getNewBrands, isLoggedIn } = Brands();
    getNewBrands();

    // Fetch Staff Picks
    getCollection("staff-picks", 3).then((res) => {
      collectionstaff.data = res;
      collectionstaff.loading = false;
    }).catch((error) => {
      console.error("Failed to fetch staff picks:", error);
    });

    // Fetch Clearance
    getCollection("clearance", 3).then((res) => {
      collectionclearance.data = res;
      collectionclearance.loading = false;
    }).catch((error) => {
      console.error("Failed to fetch clearance:", error);
    });

    const {
      categories,
      getCategories,
      topSellProducts,
      getTopSellProducts,
    } = Categories();
    getCategories();
    getTopSellProducts();
    const { TrackUserLogin, BannerVisit, BannerView, ProductClicked } = Segments();

    function getAds() {
      Banners.loading = true;
      axios.api
        .get("/advertisements")
        .then((res) => {
          Banners.data = res.data.data;
        })
        .finally(() => (Banners.loading = false));
    }
    getAds();

    const getAuctions = async () => {
      try {
        const names = ["T1", "T2", "B1", "B2", "C1"];

        let params = {
          "auctions": []
        };
        names.forEach(async (name) => {
          params.auctions.push({
            "type": "banners",
            "slots": 1,
            "slotId": name,
          });
        });


        const response = await axios1.post('https://api.topsort.com/v2/auctions', params, {
          headers: {
            Authorization: `Bearer TSE_4rVcTKALi2u28OWv5g6xHXTXoPfod43m4TDB`
          }
        });

        const res = await axios1.post('https://api.topsort.com/v2/auctions', {
          "auctions": [
            {
              "type": "banners",
              "slots": 1,
              "slotId": "C2",
            }
          ]
        }, {
          headers: {
            Authorization: `Bearer TSE_4rVcTKALi2u28OWv5g6xHXTXoPfod43m4TDB`
          }
        });

        auctions.value = response.data.results;
        auctions.value.push(res.data.results[0]);

        BannerView(auctions.value);
      } catch (error) {
        console.error("Error fetching auctions:", error);
      }
    };

    getAuctions();

    function gotToPage(item, pos, list_id) {
      let id = list_id;

      if(list_id == "clearance"){
        id = list_id;
      }
      if(list_id == "trending"){
        id = list_id;
      }

      let data = {
        id: id,
        type: 'home',
        price: Math.max(...item.variants.map(r => r.wholesale_price)).toFixed(2),
      };

      // set list
      store.dispatch("setList", {
        list_id: data.id,
        list_type: data.type,
        position: pos+1,
      });
      ProductClicked(router, item, pos+1, data);
      router.push("/products/" + item.slug);
    }
    
    const getImage = (item) => {
      if(item.image_url == null){
        return item.variants[0]?.ImageThumbnailUrl;
      }
      return item.image_url;
    }

    const getHref = (banner, temp) => {
      // Ensure winners is an array
      const winners = Array.isArray(banner?.winners) ? banner.winners : [];
      if (winners.length === 0) {
        return (temp ? temp : '/');
      }

      // Get the first winner
      const winner = winners[0];
      const href = winner?.type === 'product'
        ? `/products/${winner?.id}`
        : typeof winner?.id === 'string'
          ? winner?.id?.replace("https://medusadistribution.com/", "/")
          : (temp ? temp : '/');

      return href;
    }

    const getBannerImage = (banner, temp) => {
      // Ensure winners is an array
      const winners = Array.isArray(banner?.winners) ? banner.winners : [];
      if (winners.length === 0) {
        return temp;
      }      
      return banner.winners[0]?.asset[0]?.url
    }

    return {
      TrackUserLogin,
      collectionstaff,
      collectionclearance,
      newBrands,
      categories,
      topSellProducts,
      isLoggedIn,
      slugUrl,
      Banners,
      showVideo,
      BannerVisit,
      gotToPage,
      getImage,
      auctions,
      getHref,
      getBannerImage
    };
  },
  methods: {
    imageBaseUrl(image) {
      const base_url_img = process.env.BASE_URL_IMG || "/";
      return base_url_img + "/" + (typeof image == 'object' ? image.path : JSON.parse(image).path);
    },
    getUrl(url) {
      return url.replace("https://beta.medusadistribution.com/", "/");
    },
    shuffled(categories) {
      const data = [...categories];
      data.reverse().forEach((item, i) => {
        const j = Math.floor(Math.random() * (i + 1));
        [data[i], data[j]] = [data[j], data[i]];
      });

      return data.slice(0, 6);
    },

    getVideoUrl(banner) {
      console.log("here we are");
      return banner?.video_url != null ? banner.video_url : "";
    },
    bannerClick(banner) {
      this.BannerVisit(banner);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  @apply w-full pb-10;

  .home-page-inner {
    @apply lg:w-full space-y-7;
  }

  .brand-rows {
    @apply grid grid-cols-1 gap-6;

    @media (min-width: 1080px) {
      @apply grid-cols-3;
    }
  }
}
.prod-item {
  @apply flex flex-col items-center space-y-1.5 leading-none text-center;

  .prod-image {
    @apply flex items-center justify-center;

    &:not(.custom) {
      @apply h-full;
      max-height: 105px;
    }

    img:not(.custom) {
      @apply block h-auto max-h-full;
      max-width: 90%;

      @media (min-width: 540px) {
        max-width: 200px;
      }
    }
  }
  p {
    @apply text-md md:text-base font-semibold text-theme-secondary w-full leading-5;
  }
  span {
    @apply text-xs md:text-sm font-medium text-theme-body;
  }
}
.new-arrivals-row {
  @apply -mx-6 md:-mx-0 w-auto min-w-full;
}
.card-color-header {
  @apply flex items-center justify-between -mx-8 -mt-6 md:rounded-t px-5 py-3 mb-4;
  background: #eee5db;

  h3 {
    @apply text-base uppercase leading-none;
  }
}
</style>
